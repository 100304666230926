import React, { useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Divider, Image, Row, Space, Typography } from 'antd';

import thumb_GroomBridePhoto from '../resources/thumb_GroomBride.jpg';
import GroomBridePhoto from '../resources/GroomBride.jpg';

import { styled } from '../style/useStitches';
import useOnScreen from '../hooks/useOnScreen';
import PersonCard from './PersonCardAlt';
import useBackgroundColor from '../hooks/useBackgroundColor';
import GuestContext from '../context/GuestContext';

const { Title } = Typography;


const Layout = styled('section', {
  display: 'inline-flex',
  flexDirection: 'column',
  width: '100%',
  background: 'var(--bg)',
  overflow: 'hidden',
  position: 'relative',
  transition: 'background 1s ease-in',
  margin: 0,
  padding: '5% 5% 5% 5%',
  boxSizing: 'border-box',
});


const Greeting: React.FC = () => {
  const config = useContext(GuestContext);
  const { t } = useTranslation('greeting');
  const parentRef = useRef<HTMLElement>(null);
  const onScreen = useOnScreen(parentRef);
  const backgroundColor = useBackgroundColor(onScreen);

  if (!config) {
    return <></>;
  }

  return (
    <Layout
      ref={parentRef}
      css={{ '--bg': backgroundColor }}
    >
      <Title className='font-cursive text-center'>{t('greeting')}</Title>
      <Title level={5} style={{
        fontWeight: 400,
      }}>
        {t('guestGreeting')}
      </Title>
      <Divider />
      <Row justify='center'>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Image
            src={GroomBridePhoto}
            preview={false}
            placeholder={
              <Image
                src={thumb_GroomBridePhoto}
                preview={false}
              />
            }
          />
        </Col>
      </Row>
      <Divider />
      <Row gutter={[16, 26]} justify='center'>
        <Col xs={24} sm={12}>
          <PersonCard type={'bride'} person={config.bride} />
        </Col>
        <Col xs={24} sm={0}>
          <Divider />
        </Col>
        <Col xs={24} sm={12}>
          <PersonCard type={'groom'} person={config.groom} />
        </Col>
      </Row>
    </Layout>
  );
};

export default Greeting;
