import dayjs from 'dayjs';

import TitleImage from './resources/TitleBackground.jpg';
import TitleImageThumb from './resources/thumb_TitleBackground.jpg';

import GalleryPhoto1 from './resources/IMG_6109.jpg';
import GalleryPhoto1Thumb from './resources/thumb_IMG_6109.jpg';
import GalleryPhoto2 from './resources/IMG_6138.jpg';
import GalleryPhoto2Thumb from './resources/thumb_IMG_6138.jpg';
import GalleryPhoto3 from './resources/IMG_6244.jpg';
import GalleryPhoto3Thumb from './resources/thumb_IMG_6244.jpg';
import GalleryPhoto4 from './resources/IMG_6338.jpg';
import GalleryPhoto4Thumb from './resources/thumb_IMG_6338.jpg';
import GalleryPhoto5 from './resources/IMG_6346.jpg';
import GalleryPhoto5Thumb from './resources/thumb_IMG_6346.jpg';
import GalleryPhoto6 from './resources/IMG_6358.jpg';
import GalleryPhoto6Thumb from './resources/thumb_IMG_6358.jpg';
import GalleryPhoto7 from './resources/IMG_6588.jpg';
import GalleryPhoto7Thumb from './resources/thumb_IMG_6588.jpg';
import GalleryPhoto8 from './resources/IMG_6600.jpg';
import GalleryPhoto8Thumb from './resources/thumb_IMG_6600.jpg';
import GalleryPhoto9 from './resources/IMG_6615.jpg';
import GalleryPhoto9Thumb from './resources/thumb_IMG_6615.jpg';
import GalleryPhoto10 from './resources/IMG_6624.jpg';
import GalleryPhoto10Thumb from './resources/thumb_IMG_6624.jpg';
import GalleryPhoto11 from './resources/IMG_6645.jpg';
import GalleryPhoto11Thumb from './resources/thumb_IMG_6645.jpg';
import YoutubeThumb from './resources/video_thumb.jpg'


import type { GuestConfig } from './types';

const weddingDate = dayjs('2024-07-06').toDate()

const Configs: GuestConfig = {
  guest: {
    id: 'TEST GUEST',
    name: 'Test Guest',
    email: 'test@none.com',
    phoneNo: '628123456789',
    giftVisible: true
  },
  weddingDate,
  events: [{
    id: 1,
    type: 'holyMatrimony',
    name: 'Gereja HKBP Bandung Barat',
    location: {
      address: 'Jl. Suryani Dalam No. 51 Belakang, Warung Muncang, Bandung Kulon, Bandung, Jawa Barat 40241',
      lat: -6.921463387903296,
      lng: 107.57791116799851,
    },
    hasRsvp: false,
    time: dayjs(weddingDate)
      .startOf('day')
      .set('hour', 8)
      .set('minute', 30)
      .toDate(),
  }, {
    id: 2,
    type: 'reception',
    name: 'Cimahi Convention Hall',
    location: {
      address: 'Jl. Raden Demang Hardjakusumah, Cibabat, Kec. Cimahi Utara, Kota Cimahi, Jawa Barat 40513',
      lat: -6.872030093119953,
      lng: 107.55510359683406,
    },
    time: dayjs(weddingDate)
      .startOf('day')
      .set('hour', 17)
      .set('minute', 30)
      .toDate(),
  }],
  groom: {
    name: 'Adi Kurniawan Parjono',
    shortName: 'Adi',
    fatherName: 'Pardjono',
    motherName: 'Khoe Siu Giau',
    childNo: null,
    accountNumbers: [{
      bank: 'BCA',
      accountNo: '5930445501'
    }],
  },
  bride: {
    name: 'Eka Juliana',
    shortName: 'Eka',
    fatherName: 'Jhonson Sinaga',
    motherName: 'Mida Nainggolan',
    childNo: null,
    accountNumbers: [{
      bank: 'BCA',
      accountNo: '3460021692'
    }],
  },
  titleImage: {
    src: TitleImage,
    srcThumb: TitleImageThumb,
  },
  galleryImages: [{
    alt: 'Beautiful in White',
    src: GalleryPhoto1,
    srcThumb: GalleryPhoto1Thumb,
  }, {
    alt: 'Gallery Photo 2',
    src: GalleryPhoto2,
    srcThumb: GalleryPhoto2Thumb,
  }, {
    alt: 'Gallery Photo 3',
    src: GalleryPhoto3,
    srcThumb: GalleryPhoto3Thumb,
  }, {
    alt: 'Gallery Photo 4',
    src: GalleryPhoto4,
    srcThumb: GalleryPhoto4Thumb,
  }, {
    alt: 'Gallery Photo 5',
    src: GalleryPhoto5,
    srcThumb: GalleryPhoto5Thumb,
  }, {
    alt: 'Gallery Photo 6',
    src: GalleryPhoto6,
    srcThumb: GalleryPhoto6Thumb,
  }, {
    alt: 'Gallery Photo 7',
    src: GalleryPhoto7,
    srcThumb: GalleryPhoto7Thumb,
  }, {
    alt: 'Gallery Photo 8',
    src: GalleryPhoto8,
    srcThumb: GalleryPhoto8Thumb,
  }, {
    alt: 'Gallery Photo 9',
    src: GalleryPhoto9,
    srcThumb: GalleryPhoto9Thumb,
  }, {
    alt: 'Gallery Photo 10',
    src: GalleryPhoto10,
    srcThumb: GalleryPhoto10Thumb,
  }, {
    alt: 'Gallery Photo 11',
    src: GalleryPhoto11,
    srcThumb: GalleryPhoto11Thumb,
  }, {
    alt: 'YoutubeVideo 1',
    src: 'ACpQRzsrrQc',
    srcThumb: YoutubeThumb,
    youtubeEmbed: true
  }],
};
export default Configs;
