import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Row, Col, Card, Input, Form, Button, message, List, Typography, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

import { styled } from '../style/useStitches';
import type { Wish, WishInput } from '../types';
import useApi from '../hooks/useApi';
import useOnScreen from '../hooks/useOnScreen';
import useBackgroundColor from '../hooks/useBackgroundColor';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../utils/text';
import GuestContext from '../context/GuestContext';

const { Title, Paragraph } = Typography

const Section = styled('section', {
  display: 'inline-flex',
  flexDirection: 'column',
  width: '100%',
  background: 'var(--bg)',
  transition: 'background 1s ease-in',
  margin: 0,
  padding: '5% 5% 5% 5%',
  boxSizing: 'border-box',
});

const Wishes = () => {
  const config = useContext(GuestContext);
  const api = useApi(config?.guest.id);
  const ref = useRef();
  const onScreen = useOnScreen(ref);
  const backgroundColor = useBackgroundColor(onScreen)
  const { t } = useTranslation('wish')

  const [form] = Form.useForm()
  const [wishes, setWishes] = useState<Wish[]>([])
  const [isSubmittingWish, setIsSubmittingWish] = useState(false);
  const [isWishesLoading, setIsWishesLoading] = useState(false);

  const refreshWishes = useCallback(() => {
    setIsWishesLoading(true)
    api.getWishes()
      .then(newWishes => {
        setWishes(newWishes);
      })
      .catch(err => message.error(t('wishLoadError', { error: err.message })))
      .finally(() => {
        setIsWishesLoading(false)
      })
  }, [api, t])
  const doSubmitWish = useCallback((values: WishInput) => {
    setIsSubmittingWish(true)
    api.postWish(values)
      .then(() => {
        message.success(t('wishPosted'), 5);
        refreshWishes();
        form.resetFields()
      })
      .catch(err => message.error(t('wishPostError', { error: err.message })))
      .finally(() => {
        setIsSubmittingWish(false)
      })
  }, [api, form, refreshWishes, t]);

  useEffect(() => {
    refreshWishes();
  }, []);

  if (!config) {
    return <></>
  }
  return (
    <>
      <Section ref={ref as any} css={{ '--bg': backgroundColor }}>
        <Title className="font-cursive text-center">
          {capitalize(t('wishes'))}
        </Title>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12}>
            <Card title={<h1>{t('postWishTitle')}</h1>} style={{ height: '100%' }}>
              <Form
                layout="vertical"
                form={form}
                onFinish={doSubmitWish}
                disabled={isSubmittingWish}
              >
                <Form.Item<WishInput>
                  label={t('common:name')}
                  name="name"
                  initialValue={config.guest.name}
                  validateTrigger="onBlur"
                  rules={[{ required: true, message: t('common:nameValidationMessage') }]}
                >
                  <Input placeholder={t('common:namePlaceholder')} />
                </Form.Item>
                <Form.Item<WishInput>
                  label={t('wishLabel')}
                  name="wish"
                  validateTrigger="onBlur"
                  rules={[{ required: true, message: t('validation.required') }]}
                >
                  <Input.TextArea rows={4} placeholder={t('wishPlaceholder')} />
                </Form.Item>
                <Row justify="end">
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      {t('common:submit')}
                    </Button>
                  </Form.Item>
                </Row>
              </Form>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Card
              title={<h1>{t('wishes')}</h1>}
              extra={(
                <Tooltip title={t('common:refresh')}>
                  <Button
                    type="default"
                    icon={<ReloadOutlined />}
                    disabled={isWishesLoading}
                    onClick={() => refreshWishes()}
                  />
                </Tooltip>
              )}
            >
              <List
                style={{
                  overflow: 'auto',
                  height: 300
                }}
                itemLayout="horizontal"
                dataSource={wishes}
                loading={isWishesLoading}
                renderItem={item => (
                  <List.Item key={item.id}>
                    <List.Item.Meta
                      title={item.name}
                      description={<Paragraph>{item.wish}</Paragraph>} />
                  </List.Item>
                )}
              />
            </Card>
          </Col>
        </Row>
      </Section>
    </>
  )
}

export default Wishes;
