const { REACT_APP_GOOGLE_MAP_API_KEY } = process.env

export function generateEmbedMapUrl(name: string, lat: number, lng: number): string {
  const gmapQuery = new URLSearchParams()
  gmapQuery.set('q', name);
  gmapQuery.set('key', REACT_APP_GOOGLE_MAP_API_KEY!);
  gmapQuery.set('center', `${lat},${lng}`);
  gmapQuery.set('zoom', '13');
  return `https://www.google.com/maps/embed/v1/place?${gmapQuery}`;
}

export function generateMapUrl(name: string, lat: number, lng: number): string {
  const gmapQuery = new URLSearchParams()
  gmapQuery.set('q', name);
  gmapQuery.set('center', `${lat},${lng}`);
  gmapQuery.set('zoom', '13');
  return `https://www.google.com/maps?${gmapQuery}`;
}