import V1ClientApi from './v1';
import MockClientApi from './mock';

import type { GuestApi } from '../types';

export default function getClientApi(guestId: string | undefined): GuestApi {
  if (process.env.NODE_ENV === 'test') {
    return new MockClientApi()
  }
  return new V1ClientApi(guestId, process.env.REACT_APP_API_URL!)
}