import { fakerID_ID as faker } from '@faker-js/faker';

import type {
  GuestApi,
  Wish,
  GuestConfig,
  WishInput,
  ClientEventReservationInput,
} from '../types';
import configs from '../configs';

const wishes: Wish[] = []
for (let i = 0; i < 10; i++) {
  const sex = i % 2 === 0 ? 'male' : 'female';
  const firstName = faker.person.firstName(sex);
  const lastName = faker.person.lastName(sex);
  const guestName = `${firstName} ${lastName}`;
  wishes.push({
    id: i,
    name: guestName,
    wish: faker.lorem.sentence(),
    guest: {
      id: faker.string.uuid(),
      name: guestName,
      email: faker.internet.email({ firstName, lastName }),
      phoneNo: faker.phone.number(),
    }
  });
}

export default class MockClientApi implements GuestApi {
  setGuestId(newGuestId: string) {
    // noop
  }

  getConfig(): Promise<GuestConfig> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(configs);
      }, 1000);
    });
  }

  getWishes(): Promise<Wish[]> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(wishes);
      }, 1000);
    });
  }

  postWish(wish: WishInput): Promise<Wish> {
    return new Promise((resolve) => {
      const newWish: Wish = {
        id: wishes.length,
        name: wish.name,
        wish: wish.wish,
        guest: {
          id: faker.string.uuid(),
          name: wish.name,
          email: faker.internet.email({ firstName: wish.name, lastName: wish.name }),
          phoneNo: faker.phone.number(),
        }
      }
      wishes.push(newWish);
      setTimeout(() => {
        resolve(newWish);
      }, 1000);
    });
  }

  putReservation(eventId: number, reservation: ClientEventReservationInput): Promise<any> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 1000);
    });
  }
}