import { type FC, useMemo } from 'react';
import { Button, Col, Divider, Grid, Image, Row, Typography } from 'antd';
import { InstagramOutlined, TwitterOutlined, FacebookOutlined, GithubOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import type { Person } from '../types';
import { capitalize } from '../utils/text';
import { useOrientation } from 'react-use';

const { useBreakpoint } = Grid;
const { Title, Paragraph } = Typography;

interface Props {
  type: 'bride' | 'groom';
  person: Person;
}

const PersonCard: FC<Props> = ({ person, type }) => {
  const breakpoints = useBreakpoint();
  const orientation = useOrientation();
  const { t } = useTranslation('greeting');
  const childIdentity = useMemo(() => {
    const transKey = type === 'bride' ? 'daughter' : 'son'
    if (!person.childNo) {
      return capitalize(t(transKey))
    }
    return capitalize(t(`${transKey}${person.childNo}`))
  },[type, person.childNo, t]);

  const fatherText = useMemo(() => capitalize(t('mr')), [t]);
  const motherText = useMemo(() => capitalize(t('mrs')), [t]);
  const socialLinks = useMemo(() => {
    if (!person.socialLinks) {
      return <></>
    }
    return Object.entries(person.socialLinks).map(([key, socialLink]) => {
      let icon: JSX.Element = <></>;
      if (key === 'twitter') {
        icon = <TwitterOutlined/>
      }
      if (key === 'instagram') {
        icon = <InstagramOutlined/>
      }
      if (key === 'facebook') {
        icon = <FacebookOutlined/>
      }
      if (key === 'github') {
        icon = <GithubOutlined/>
      }
      return (
        <Button
          key={key}
          icon={icon}
          href={socialLink.url}
          target="_blank"
          rel="noreferrer"
          type="link"
        >
          {socialLink.id}
        </Button>
      )
    })
  }, [person.socialLinks])

  const fontSize = useMemo(() => {
    if (orientation.type.includes('portrait')) {
      return '1.5rem'
    }
    if (orientation.type.includes('landscape')) {
      return breakpoints.md ? '1.2rem' : '1.5rem'
    }
  }, [breakpoints, orientation])

  return (
    <section>
      <Title level={2} className="font-serif" style={{
        fontWeight: 500,
        textAlign: 'center',
        marginTop: '0.5rem',
      }}>
        {person.name}
      </Title>
      <Row justify="center" align='middle'>
        <Col
          sm={24}
          md={12}
          lg={14}
          style={{ width: '100%' }}
        >
          <Paragraph style={{
            marginTop: '1rem',
            textAlign: 'center',
            height: '100%',
            fontSize
          }}>
            <em>{childIdentity}</em><br/><br/>
            {fatherText} <strong>{person.fatherName}</strong><br/>
            {person.fatherNameAlt && (
              <span><strong>({person.fatherNameAlt})</strong><br/></span>
            )}
            &amp;<br/>
            {motherText} <strong>{person.motherName}</strong>
            {person.motherNameAlt && (
              <strong>({person.fatherNameAlt})</strong>
            )}
          </Paragraph>
          {person.socialLinks && (
            <Paragraph
              style={{
                textAlign: 'center',
                fontSize: breakpoints.md ? '1.2rem' : '1rem',
              }}
            >
              {socialLinks}
            </Paragraph>
          )}
        </Col>
      </Row>
    </section>
  )
}
export default PersonCard;
