import { Button, Col, Divider, Grid, message, Modal, Row, Typography } from 'antd';
import { useContext, useMemo, useRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import ReactMarkdown from 'react-markdown';

import { useTranslation } from 'react-i18next';

import { styled } from '../style/useStitches';
import useOnScreen from '../hooks/useOnScreen';
import useBackgroundColor from '../hooks/useBackgroundColor';
import GuestContext from '../context/GuestContext';

const Section = styled('section', {
  background: 'var(--bg)',
  overflow: 'hidden',
  position: 'relative',
});

const { Title } = Typography

const Layout = styled('div', {
  width: '100%',
  padding: '10% 5% 10% 5%',
  boxSizing: 'border-box',
  '@md': {
    padding: '5% 5% 5% 5%'
  }
});

const TEXT_EN = `
Your presence and prayer will be the greatest wedding gift we could ask for.\  
It would mean a world to us.\    
However, if giving is a sign of love, it will complement our own happiness even more.\  
`
const TEXT_ID = `
Kehadiran dan doa Anda merupakan hadiah pernikahan terbaik yang kami dapat minta.\  
Maknanya sangat besar bagi kami.\    
Namun demikian, jika memberi adalah tanda kasih, maka itu akan mengkomplemen kebahagian kami lebih lagi.\  
`

const WeddingGift = () => {
  const config = useContext(GuestContext);
  const ref = useRef();
  const onScreen = useOnScreen(ref);
  const backgroundColor = useBackgroundColor(onScreen);
  const { t, i18n } = useTranslation('weddingGift');
  const textFragment = useMemo(() => (
    <ReactMarkdown children={i18n.resolvedLanguage === 'id' ? TEXT_ID : TEXT_EN} />
  ), [i18n.resolvedLanguage])

  if (!config) {
    return <></>
  }
  if (!config.guest.giftVisible) {
    return <></>
  }
  return (
    <Section ref={ref as any} css={{ '--bg': backgroundColor }}>
      <Layout>
        <Title level={3} className="font-cursive text-center">
          {t('title')}
        </Title>
        <Title level={5} className="text-center" style={{ fontWeight: 300 }}>
          {textFragment}
        </Title>
        <Row gutter={24} align="middle" justify="center">
          <Col sm={24} md={12}>
            <div className='text-center'>
              <p><strong>{config.groom.name}</strong></p>
              {config.groom.accountNumbers.map((account, index) => (
                <CopyToClipboard text={account.accountNo} key={index}>
                  <Button
                    size='large'
                    onClick={() => message.info(t('clipboardPasted'), 5)}
                  >
                    {account.bank} {account.accountNo}
                  </Button>
                </CopyToClipboard>
              ))}
            </div>
          </Col>
          <Col sm={24} md={12}>
            <div className="text-center">
              <p><strong>{config.bride.name}</strong></p>
              {config.bride.accountNumbers.map((account, index) => (
                <CopyToClipboard text={account.accountNo} key={index}>
                  <Button
                    size='large'
                    onClick={() => message.info(t('clipboardPasted'), 5)}
                  >
                    {account.bank} {account.accountNo}
                  </Button>
                </CopyToClipboard>
              ))}
            </div>
          </Col>
        </Row>
      </Layout>
    </Section>
  );
};

export default WeddingGift;
