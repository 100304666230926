import { useContext, useMemo } from 'react';

import getClientApi from '../api';
import GuestContext from '../context/GuestContext';

const useApi = (guestId: string | undefined) => {
  const guestConfig = useContext(GuestContext);
  const api = useMemo(() => getClientApi(guestConfig?.guest?.id), [guestConfig]);
  return api;
}

export default useApi;