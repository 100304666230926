import type { ClientEventReservationInput, Wish, WishInput, GuestApi, GuestConfig } from '../types';


export default class V1ClientApi implements GuestApi {
  protected guestId: string | undefined;
  protected baseUrl: string;

  public constructor(
    clientId: string | undefined,
    baseUrl: string,
  ) {
    this.guestId = clientId;
    this.baseUrl = baseUrl;
  }

  public setGuestId(newGuestId: string) {
    this.guestId = newGuestId;
  }

  protected checkGuestId() {
    if (!this.guestId) {
      throw new Error('guestId not set!')
    }
  }

  public async getWishes() {
    try {
      this.checkGuestId();
      const response = await fetch(`${this.baseUrl}/guest/wishes`, {
        headers: {
          'X-Guest-Id': this.guestId!,
        },
      });
      return await response.json().then((data) => data.data) as Promise<Wish[]>;
    } catch (err) {
      // TODO logging
      console.error(err);
      throw err;
    }
  }

  public async postWish(wish: WishInput) {
    try {
      this.checkGuestId();
      const response = await fetch(`${this.baseUrl}/guest/wishes`, {
        method: 'POST',
        body: JSON.stringify(wish),
        headers: {
          'X-Guest-Id': this.guestId!,
          'Content-Type': 'application/json'
        },
      });
      return await response.json().then((data) => data.data) as Promise<Wish>;
    } catch (err) {
      // TODO logging
      console.error(err);
      throw err;
    }
  }

  public async putReservation(eventId: number, reservation: ClientEventReservationInput) {
    try {
      this.checkGuestId();
      const response = await fetch(`${this.baseUrl}/guest/events/${eventId}/reservations`, {
        method: 'PATCH',
        body: JSON.stringify(reservation),
        headers: {
          'X-Guest-Id': this.guestId!,
          'Content-Type': 'application/json'
        },
      });
      return await response.json().then((data) => data.data);
    } catch (err) {
      // TODO logging
      console.error(err);
      throw err;
    }
  }

  public async getConfig(): Promise<GuestConfig> {
    try {
      this.checkGuestId();
      const response = await fetch(`${this.baseUrl}/guest`, {
        headers: {
          'X-Guest-Id': this.guestId!,
        },
      });
      return await response.json().then((data) => data.data);
    } catch (err) {
      // TODO logging
      console.error(err);
      throw err;
    }
  }
}