import { useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Divider, Image, Row, Space, Typography } from 'antd';
import YouTube, { type YouTubeProps } from 'react-youtube';

import { styled } from '../style/useStitches';
import useOnScreen from '../hooks/useOnScreen';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import useBackgroundColor from '../hooks/useBackgroundColor';
import GuestContext from '../context/GuestContext';

const { Title } = Typography;


const Layout = styled('div', {
  background: 'var(--bg)',
  display: 'inline-flex',
  flexDirection: 'column',
  width: '100%',
  overflow: 'hidden',
  position: 'relative',
  transition: 'background 1s ease-in',
  margin: 0,
  padding: '5% 5% 5% 5%',
  boxSizing: 'border-box',
});

interface Props {
  onVideoPlayed?: () => void;
}

const Gallery = ({ onVideoPlayed }: Props) => {
  const config = useContext(GuestContext);

  const ref = useRef<HTMLElement>(null);
  const onScreen = useOnScreen<HTMLDivElement>(ref, '-125px');
  const backgroundColor = useBackgroundColor(onScreen);
  const { t } = useTranslation()

  const imagePreview = useMemo(() => (
    <Image.PreviewGroup
      preview={{
        imageRender: (originalNode, {current}) => {
          const originalImage = config?.galleryImages[current]
          if (originalImage?.youtubeEmbed) {
            return (
              <YouTube
                className="video-responsive"
                videoId={originalImage.src}
                title={originalImage.alt}
                opts={{
                  host: 'https://www.youtube-nocookie.com',
                  origin: window.location.host,
                  playerVars: {
                    controls: 0,
                    rel: 0
                  }
                }}
                onPlay={() => onVideoPlayed && onVideoPlayed()}
              />
            )
          }
          return originalNode
        },
        toolbarRender: (_, {
          transform: { scale },
          actions: { onZoomIn, onZoomOut },
          current
        }) => {
          const originalImage = config?.galleryImages[current]
          if (originalImage?.youtubeEmbed) {
            return (
              <Space size={12} className="ant-image-preview-operations">
              </Space>
            )
          }
          return (
            <Space size={12} className="ant-image-preview-operations">
              <ZoomOutOutlined disabled={scale <= 1} onClick={onZoomOut} />
              <ZoomInOutlined disabled={scale >= 50} onClick={onZoomIn} />
            </Space>
          )
        },
      }} >
      {config?.galleryImages.map((image, index) => (
        <Col
          key={index}
          xs={12}
          md={8}
          lg={6}
          xxl={4}
        >
          <Image src={image.srcThumb} alt={image.alt} preview={{
            src: image.src,
          }} />
        </Col>
      ))}
    </Image.PreviewGroup>
), [config?.galleryImages, onVideoPlayed])

  if (!config) {
    return <></>;
  }

  return (
    <Layout ref={ref as any} css={{
      '--bg': backgroundColor
    }}>
      <Title className="text-center font-cursive">
        {t('common:gallery')}
      </Title>
      <Divider type="horizontal" style={{ border: '2.5rem' }}/>
      <Row gutter={[16, 26]}>{imagePreview}</Row>
    </Layout>
  );
};

export default Gallery;
