import { useCallback, useRef, type ElementRef, useMemo, useEffect, useState } from 'react';
import { App as AntdApp, Layout, ConfigProvider, FloatButton, theme, Tooltip, Skeleton, Result } from 'antd';
import { PlayCircleOutlined, PauseCircleOutlined, BulbOutlined } from '@ant-design/icons';
import { useDarkMode } from 'usehooks-ts';
import useSound from 'use-sound';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import getClientApi from './api';

import './i18n';
import Gallery from './components/Gallery';
import Greeting from './components/Greeting';
import TitleLayout from './components/Title';
import Event from './components/Event';
import WeddingGift from './components/WeddingGift';
import Wishes from './components/Wishes';
import BackgroundMusic from './resources/Make You Feel My Love.m4a';
import Quote from './components/Quote';
import GuestContext from './context/GuestContext';
import type { GuestConfig } from './types';
import DefaultConfig from './configs';
import useBackgroundColor from './hooks/useBackgroundColor';

const { Footer } = Layout;

function onLocaleChanged(lng: string) {
  require(`dayjs/locale/${lng}.js`);
  dayjs.locale(lng);
}

function App() {
  const modalContainerRef = useRef<any>();
  const quoteRef = useRef<ElementRef<typeof Quote>>(null);
  const { isDarkMode, toggle: toggleDarkMode } = useDarkMode();
  const { t, i18n } = useTranslation()

  const [noGuestError, setNoGuestError] = useState<string | null>(null);
  const [guestConfig, setGuestConfig] = useState<GuestConfig | null>(null)

  const [isBgSoundPlaying, setIsBgSoundPlaying] = useState(false);
  const [playBgSound, {
    pause: pauseBgSound,
    stop: stopBgSound
  }] = useSound(BackgroundMusic, {
    loop: true,
    onplay: () => setIsBgSoundPlaying(true),
    onpause: () => setIsBgSoundPlaying(false),
    onend: () => setIsBgSoundPlaying(false)
  })
  const toggleLanguage = useCallback(async () => {
    if (i18n.resolvedLanguage === 'en') {
      await i18n.changeLanguage('id');
    } else {
      await i18n.changeLanguage('en');
    }
  }, [i18n.resolvedLanguage]);

  useEffect(() => {
    window.history.scrollRestoration = 'manual'

    // initialize guest config
    const guestId = new URLSearchParams(window.location.search).get('guest_id');
    // if (!guestId) {
    //   setNoGuestError(t('common:noGuestId'));
    //   return;
    // }
    const api = getClientApi(guestId!);
    api.getConfig().then((config) => {
      const userConfig: typeof config = {
        ...DefaultConfig,
        ...config,
      }
      setGuestConfig(userConfig)
    }).catch((err) => {
      setNoGuestError(t('common:guestConfigError', { error: err.message }))
    })

    onLocaleChanged(i18n.resolvedLanguage!);
    i18n.on('languageChanged', onLocaleChanged);
    return () => {
      stopBgSound()
      i18n.off('languageChanged', onLocaleChanged);
    }
  }, []);

  const onInvitationOpened = useCallback(() => {
    if (quoteRef.current && quoteRef.current.scrollIntoView) {
      quoteRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
    if (!isBgSoundPlaying) {
      playBgSound()
    }
  }, [isBgSoundPlaying, playBgSound]);
  const musicIcon = useMemo(() => isBgSoundPlaying
    ? <PauseCircleOutlined/>
    : <PlayCircleOutlined/>,
    [isBgSoundPlaying]
  )
  const onMusicButtonClicked = useCallback(() => {
    if (isBgSoundPlaying) {
      pauseBgSound()
    } else {
      playBgSound()
    }
  }, [isBgSoundPlaying, pauseBgSound, playBgSound]);
  const musicButtonTooltip = useMemo(() => isBgSoundPlaying
      ? t('common:music.pause')
      : t('common:music.play'),
  [isBgSoundPlaying, t]);

  const languageIcon = useMemo(() => i18n.resolvedLanguage! === 'id'
    ? <img src="https://purecatamphetamine.github.io/country-flag-icons/3x2/ID.svg" alt="Indonesia" />
    : <img src="https://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg" alt="English" />,
    [i18n.resolvedLanguage]
  )

  const background = useBackgroundColor(true);
  const innerApp = useMemo(() => {
    if (!guestConfig) {
      if (noGuestError) {
        return (
          <Result
            status="error"
            title={t('common:noGuestErrorTitle')}
            subTitle={noGuestError}
            style={{ background, width: '100vh', height: '100vh' }}
          />
        )
      }

      return (
        <Skeleton style={{
          width: '100vh',
          height: '100vh',
        }} active />
      );
    }
    return (
      <>
        <div ref={modalContainerRef} />
        <TitleLayout onOpenInvitationClicked={onInvitationOpened} />
        <Quote ref={quoteRef} />
        <Greeting />
        <Event />
        <Gallery />
        {guestConfig.guest?.giftVisible && (<WeddingGift />)}
        <Wishes />
        <FloatButton.Group shape="circle">
          <Tooltip placement="left" title={t('common:toggleDarkMode')}>
            <FloatButton
              icon={<BulbOutlined />}
              onClick={toggleDarkMode}
              />
          </Tooltip>
          <Tooltip placement="left" title={musicButtonTooltip}>
            <FloatButton
              type="primary"
              icon={musicIcon}
              onClick={onMusicButtonClicked}
            />
          </Tooltip>
        </FloatButton.Group>
        <Footer
          style={{
            opacity: 0.8,
            textAlign: 'center',
          }}
        >
          Copyright © {new Date().getFullYear()} <a href="https://github.com/alvin-nt">Alvin Natawiguna</a>
        </Footer>
      </>
    )
  }, [
    guestConfig, noGuestError, background,
    onInvitationOpened, musicIcon, onMusicButtonClicked, musicButtonTooltip,
    languageIcon, toggleLanguage, toggleDarkMode,
    t,
  ]);

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
        token: {
          colorPrimary: '#ffc53d',
          fontFamily: `'Noto Sans', sans-serif`,
          fontSize: 16,
        },
        components: {
          Divider: {
            fontSize: 24,
            fontSizeSM: 12,
          }
        }
      }}
      getPopupContainer={() => modalContainerRef.current as HTMLElement}
    >
      <GuestContext.Provider value={guestConfig}>
        <AntdApp>{innerApp}</AntdApp>
      </GuestContext.Provider>
    </ConfigProvider>
  );
}

export default App;
