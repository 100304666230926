import { useCallback, useContext, useMemo, useState } from 'react';
import { Form, Radio, Modal, message, Button } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import type { ClientEvent, ClientEventReservationInput } from '../types';
import useApi from '../hooks/useApi';
import { capitalize, capitalizeAll } from '../utils/text';
import GuestContext from '../context/GuestContext';

type Props = {
  event: ClientEvent | undefined;
  isOpen: boolean;
  onModalClosed?: () => void;
}

const ReservationModal = ({
  event,
  isOpen,
  onModalClosed
}: Props) => {
  const config = useContext(GuestContext);
  const api = useApi(config?.guest.id);
  const { t } = useTranslation('event');
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm<ClientEventReservationInput>();
  const isConfirmed = Form.useWatch('confirmed', form);

  const onCancel = useCallback(() => onModalClosed && onModalClosed(), [onModalClosed]);
  const onSubmit = useCallback((input: ClientEventReservationInput) => {
    if (!event) {
      return;
    }
    setIsLoading(true);
    api.putReservation(event.id!, input)
      .then(() => {
        message.success(t('confirmRsvpSuccess'));
        onCancel();
      })
      .catch((err) => message.error(err.message || 'Something went wrong.'))
      .finally(() => {
        setIsLoading(false);
      });
  }, [api, event, onCancel]);
  const modalTitle = useMemo(() =>
    capitalize(t('confirmRsvpTitle', { event: capitalizeAll(t(event?.type ?? 'event')) })),
    [event, t],
  );
  const eventDetails = useMemo(() => {
    if (!event) {
      return <p className="ant-form-text"></p>;
    }
    return (
      <p className="ant-form-text">
        <strong>{event.name}</strong><br/>
        {event.location.address}
      </p>
    )
  }, [event]);
  const eventTime = useMemo(() => dayjs(event?.time).format('dddd, DD MMMM YYYY HH:mm'), [event]);
  const modalFooter = useMemo(() => [
    <Button key="back" onClick={onCancel}>
      {t('common:cancel')}
    </Button>,
    <Button
      key="submit"
      type="primary"
      disabled={isConfirmed === undefined}
      loading={isLoading}
      onClick={() => form.submit()}
    >
      {t('common:submit')}
    </Button>
  ], [onCancel, isLoading, form, isConfirmed])
  const radioOptions = useMemo(() => {
    if (!event) {
      return <></>
    }
    const options: number[] = [];
    for (let i = 1; i <= (event.maxRsvpCount || 2); i++) {
      options.push(i);
    }
    return (
      <>
        {options.map((option) => (
          <Radio.Button key={option} value={option}>{option}</Radio.Button>
        ))}
      </>
    )
  }, [event])

  return (
    <Modal
      title={modalTitle}
      open={isOpen}
      onCancel={onCancel}
      afterClose={() => {
        form.resetFields();
      }}
      centered
      footer={modalFooter}
    >
      <Form
        form={form}
        layout="vertical"
        disabled={isLoading}
        onFinish={onSubmit}
      >
        <Form.Item label={capitalize(t('common:address'))}>
          {eventDetails}
        </Form.Item>
        <Form.Item label={capitalize(t('common:time'))}>
          <span className="ant-form-text">
            <strong>{eventTime}</strong>
          </span>
        </Form.Item>
        <Form.Item<ClientEventReservationInput>
          label={t('confirmLabel')}
          name="confirmed"
        >
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
          >
            <Radio.Button value={false}>{t('common:no')}</Radio.Button>
            <Radio.Button value={true}>{t('common:yes')}</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item<ClientEventReservationInput>
          label={t('paxCountLabel')}
          name="paxCount"
          hidden={!isConfirmed}
          initialValue={1}
          rules={[{
            required: isConfirmed,
            message: t('paxCountValidationMessage')
          }]}
        >
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
          >
            {radioOptions}
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ReservationModal;
