import { useDarkMode } from 'usehooks-ts';
import { useMemo } from 'react';

const BG_COLOR_NOT_VISIBLE = '#DADADA';
export const BG_COLOR_VISIBLE_LIGHT = '#f1f1f1';
export const BG_COLOR_VISIBLE_DARK = '#1f1f1f';
export default function useBackgroundColor(onScreen: boolean) {
  const { isDarkMode } = useDarkMode()
  const color =  useMemo(() =>
    onScreen ? (isDarkMode ? BG_COLOR_VISIBLE_DARK : BG_COLOR_VISIBLE_LIGHT) : BG_COLOR_NOT_VISIBLE,
    [onScreen, isDarkMode]
  );
  return color;
}