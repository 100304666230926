import { useRef, useMemo, useState, useCallback, useContext, type FC } from 'react';
import dayjs from 'dayjs';
import { google, yahoo, ics, type CalendarEvent, outlook } from 'calendar-link';
import { useTranslation } from 'react-i18next';
import { Card, Row, Col, Button, Tooltip, Dropdown, Space, Typography } from 'antd';
import {
  MailOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
  PushpinOutlined,
  GoogleOutlined,
  YahooOutlined,
  WindowsOutlined,
} from '@ant-design/icons';

import { capitalizeAll } from '../utils/text';
import ReservationModal from './ReservationModal';
import { styled } from '../style/useStitches';
import GuestContext from '../context/GuestContext';
import type { ClientEvent } from '../types';
import useOnScreen from '../hooks/useOnScreen';
import useBackgroundColor from '../hooks/useBackgroundColor';

import { generateEmbedMapUrl, generateMapUrl } from '../utils/google-map';

const { Title, Paragraph } = Typography;

const Section = styled('section', {
  display: 'inline-flex',
  flexDirection: 'column',
  width: '100%',
  background: 'var(--bg)',
  transition: 'background 1s ease-in',
  margin: 0,
  padding: '5% 5% 5% 5%',
  boxSizing: 'border-box',
});

type AddToCalendarButtonProps = {
  eventName: string;
  groom: string;
  bride: string;
  startTime: Date;
  duration?: [number, dayjs.UnitType];
  location: string;
  key?: string;
}

const AddToCalendarButton: FC<AddToCalendarButtonProps> = ({
  key = 'add-to-calendar',
  eventName, groom, bride,
  startTime, duration = [2, 'hours'], location
}) => {
  const { t } = useTranslation('event');
  const calendarTitle = useMemo(() => {
    return t('calendarEventTitle', { event: eventName, groom, bride })
  }, [t, eventName, groom, bride]) ;
  const calendarEvent = useMemo(() => ({
    title: calendarTitle,
    start: startTime,
    duration,
    location,
  }), [calendarTitle, startTime, duration, location])

  return (
    <Tooltip title={t('addToCalendar')} key={key}>
      <Dropdown menu={{
        items: [{
          key: 'google',
          label: (
            <a href={google(calendarEvent)} target="_blank" rel="noreferrer">
              <Space>
                <GoogleOutlined />
                Google
              </Space>
            </a>
          )
        }, {
          key: 'yahoo',
          label: (
            <a href={yahoo(calendarEvent)} target="_blank" rel="noreferrer">
              <Space>
                <YahooOutlined />
                Yahoo
              </Space>
            </a>
          )
        }, {
          key: 'outlook',
          label: (
            <a href={outlook(calendarEvent)} target="_blank" rel="noreferrer">
              <Space>
                <WindowsOutlined />
                Outlook
              </Space>
            </a>
          )
        }, {
          key: 'calendar',
          label: (
            <a href={ics(calendarEvent)} target="_blank" rel="noreferrer">
              <Space>
                <CalendarOutlined />
                ICS
              </Space>
            </a>
          )
        }]
      }} trigger={['click']}>
        <Button block icon={<CalendarOutlined />} type="text" />
      </Dropdown>
    </Tooltip>
  )
}

type GoogleMapEmbedProps = {
  name: string;
  lat: number;
  lng: number;
  width?: string;
  height?: string;
}

const GoogleMapEmbed: FC<GoogleMapEmbedProps> = ({ name, lat, lng, width = '100%', height = '100%' }) => {
  const embedUrl= useMemo(() => generateEmbedMapUrl(name, lat, lng), [name, lat, lng])
  return (
    <iframe
      width={width}
      height={height}
      title={name}
      style={{ border: 0 }}
      src={embedUrl}
    />
  )
}

const Event: FC = () => {
  const ref = useRef<HTMLSelectElement>(null);
  const config = useContext(GuestContext);
  const { t, i18n, ready: i18nReady } = useTranslation('event');

  const onScreen: boolean = useOnScreen<HTMLDivElement>(ref, '-125px');
  const backgroundColor = useBackgroundColor(onScreen);
  const dateFormat = useCallback((date: string | Date) => dayjs(date)
    .locale(i18n.resolvedLanguage!)
    .format('dddd, DD MMMM YYYY | HH:mm'),
    [i18n.resolvedLanguage, i18nReady, onScreen]
  )
  const locationsComponent = useMemo(() => config?.events.map((weddingEvent, index, events) => {
    const title = capitalizeAll(t(weddingEvent.type))

    let mapFragment = <></>
    let mapUrl: string | undefined = undefined;
    const { location } = weddingEvent;
    if (location.lat && location.lng) {
      mapUrl = generateMapUrl(weddingEvent.name, location.lat, location.lng);
      mapFragment = (
        <GoogleMapEmbed
          name={weddingEvent.name}
          lat={location.lat}
          lng={location.lng}
        />
      );
    }

    const { hasRsvp = true } = weddingEvent
    const actions = [];
    if (hasRsvp) {
      actions.push(
        <AddToCalendarButton
          key="add-to-calendar"
          location={weddingEvent.location.address}
          eventName={weddingEvent.name}
          startTime={weddingEvent.time}
          groom={config?.groom.shortName}
          bride={config?.bride.shortName}
        />,
        <Button
          type="primary"
          key="rsvp"
          block
          icon={<MailOutlined />}
          onClick={() => setSelectedEvent(weddingEvent)}
        >
          {t('rsvp')}
        </Button>,
      )
    }
    if (mapUrl) {
      actions.push(
        <Tooltip title={t('viewMap')}>
          <Button
            type="text"
            key="view-map"
            block
            icon={<PushpinOutlined />}
            href={mapUrl}
          />
        </Tooltip>
      )
    }
    return (
      <Col
        key={index}
        xs={24}
        md={24 / Math.min(events.length, 2)}
        lg={24 / Math.min(events.length, 3)}
        xl={24 / Math.min(events.length, 4)}
      >
        <Card
          title={<h1>{title}</h1>}
          style={{ width: '100%', height: '100%' }}
          cover={mapFragment}
          actions={actions}
        >
          <Card.Meta title={weddingEvent.name} description={
            <>
              <Paragraph>{weddingEvent.location.address}</Paragraph>
              <Title level={4} className="text-center">
                <strong><ClockCircleOutlined /> {dateFormat(weddingEvent.time)}</strong>
              </Title>
            </>
          }/>
        </Card>
      </Col>
    )
  }), [config, dateFormat]);
  const [selectedEvent, setSelectedEvent] = useState<ClientEvent>()

  if (!config) {
    return <></>;
  }

  return (
    <>
      <Section ref={ref} css={{ '--bg': backgroundColor }}>
        <Title className="font-cursive text-center">{t('eventTitle')}</Title>
        <Row gutter={32}>
          {locationsComponent}
        </Row>
      </Section>
      <ReservationModal
        event={selectedEvent}
        isOpen={!!selectedEvent}
        onModalClosed={() => setSelectedEvent(undefined)}
      />
    </>
  );
};

export default Event;
