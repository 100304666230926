// eslint-disable no-useless-escape
import { forwardRef, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from 'antd';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { useDarkMode } from 'usehooks-ts';

import hexRgb from 'hex-rgb';

import Background from '../resources/QuoteBackground.jpg';
import BackgroundThumb from '../resources/thumb_QuoteBackground.jpg';

import useBackgroundColor, {
  BG_COLOR_VISIBLE_DARK,
  BG_COLOR_VISIBLE_LIGHT,
} from '../hooks/useBackgroundColor';
import { styled } from '../style/useStitches';
import GuestContext from '../context/GuestContext';
import useOnScreen from '../hooks/useOnScreen';

const { Paragraph } = Typography;

const Layout = styled('section', {
  minHeight: '100vh',
  overflow: 'hidden',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'background 1s ease-in',

  '&::before': {
    content: `''`,
    left: 0,
    right: 0,
    zIndex: -1,
    position: 'absolute',
    top: '-5%',
    width: '110%',
    height: '110%',
    backgroundImage: 'var(--bg-img)',
    backgroundSize: 'cover',
    backgroundPosition: '60% 30%',

    '-webkit-filter': 'var(--blur-config)',
    '-moz-filter': 'var(--blur-config)',
    '-o-filter': 'var(--blur-config)',
    '-ms-filter': 'var(--blur-config)',
    filter: 'var(--blur-config)',
    '@xl': {
      backgroundPosition: '60% 30%',
    }
  },
});

const QUOTE_EN = `
Love is patient, love is kind. It does not envy, it does not boast, it is not proud.&nbsp;\
It does not dishonor others, it is not self-seeking,&nbsp;\
it is not easily angered, it keeps no record of wrongs.&nbsp;\
Love does not delight in evil but rejoices with the truth.&nbsp;\
It always protects, always trusts, always hopes, always perseveres.&nbsp;\
<small>*1 Cor. 13:4-7 (NIV)*</small>
`;
const QUOTE_ID = `
Kasih itu sabar; kasih itu murah hati; ia tidak cemburu. Ia tidak memegahkan diri dan tidak sombong.\  
Ia tidak melakukan yang tidak sopan dan tidak mencari keuntungan diri sendiri.\  
Ia tidak pemarah dan tidak menyimpan kesalahan orang lain.\  
Ia tidak bersukacita karena ketidakadilan, tetapi karena kebenaran.\  
Ia menutupi segala sesuatu, percaya segala sesuatu, mengharapkan segala sesuatu, sabar menanggung segala sesuatu.\  
<small>*1 Kor. 13:4-7 (TB)*</small>
`;

const Quote = forwardRef<HTMLElement>((_, ref) => {
  const breakpoints = Grid.useBreakpoint();
  const { i18n } = useTranslation();
  const quoteText = useMemo(() => i18n.resolvedLanguage === 'en'
    ? QUOTE_EN
    : QUOTE_ID, [i18n.resolvedLanguage]
  );
  const { isDarkMode } = useDarkMode()
  const [isBgImageLoaded, setIsBgImageLoaded] = useState(false)
  const config = useContext(GuestContext);
  const onScreen = useOnScreen(ref as any);

  const overlayBg = useMemo(() => isDarkMode
    ? hexRgb(BG_COLOR_VISIBLE_LIGHT, { alpha: 0.1, format: 'css' })
    : hexRgb(BG_COLOR_VISIBLE_DARK, { alpha: 0.1, format: 'css' }),
    [isDarkMode]);

  useEffect(() => {
    if (!config) {
      return
    }
    const img = new Image();
    img.src = Background;
    img.onload = () => {
      setIsBgImageLoaded(true);
    }
  }, [config]);

  const bgCss = useMemo(() => isBgImageLoaded ? {
    '--bg-img': `url(${Background})`,
    '--blur-config': 'blur(2px) brightness(0.3)',
  } : {
    '--bg-img': `url(${BackgroundThumb})`,
    '--blur-config': 'blur(6px) brightness(0.3)',
  }, [isBgImageLoaded])

  if (!config) {
    return <></>
  }

  return (
    <Layout ref={ref as any} css={bgCss} className="text-center">
      <Paragraph style={{
        background: overlayBg,
        padding: '2rem',
        fontSize: breakpoints.md ? '1.5rem' : '1.2rem',
        width: '100%',
        color: 'white'
      }}>
        <ReactMarkdown children={quoteText} rehypePlugins={[rehypeRaw as any]} />
      </Paragraph>
    </Layout>
  )
});

export default Quote;
