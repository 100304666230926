import React, { useContext } from 'react';
import Confetti from 'react-confetti';
import { useWindowSize, useLockBodyScroll, useOrientation } from 'react-use';
import { MailOutlined } from '@ant-design/icons'
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { styled } from '../style/useStitches';
import { useEffect, useMemo, useRef, useState } from 'react';
import GuestContext from '../context/GuestContext';

const Section = styled('section', {
  height: '100%',
  overflow: 'hidden',
  position: 'relative',

  '&::before': {
    content: `''`,
    left: 0,
    right: 0,
    zIndex: -1,
    position: 'absolute',
    top: '-5%',
    width: '110%',
    height: '110%',
    backgroundImage: 'var(--bg-img)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'var(--bg-position)',

    '-webkit-filter': 'var(--blur-config)',
    '-moz-filter': 'var(--blur-config)',
    '-o-filter': 'var(--blur-config)',
    '-ms-filter': 'var(--blur-config)',
    filter: 'var(--blur-config)',
  },
});

const Header = styled('div', {

})

const Footer = styled('div', {

})


const Layout = styled('div', {
  position: 'initial',
  height: '100%',
  width: '100%',
  color: '#ffffff',
  textAlign: 'center',
  animation: 'fadein 2.5s',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around'
});

const CardHeader = styled('h2', {
  width: '100%',
  margin: '0.2rem 0.3rem',
  fontWeight: '300',
  fontSize: '1.2rem',

  '@lg': {
    fontSize: '3rem'
  },
  '@md': {
    fontSize: '2rem'
  }
});

const BrideGroomShortName = styled('h1', {
  width: '100%',
  margin: '0.5rem 0.3rem',
  fontWeight: '400',
  fontSize: '3rem',

  '@sm': {
    fontSize: '3rem'
  },
  '@md': {
    fontSize: '6rem'
  }
});

const Hashtag = styled('h2', {
  width: '100%',
  margin: '0.1rem 0.3rem',
  fontWeight: '300',
  fontSize: '1.2rem',

  '@lg': {
    fontSize: '3rem'
  },
  '@md': {
    fontSize: '2rem'
  }
});

const GuestName = styled('p', {
  fontWeight: 300,
  '@sm': {
    fontSize: '1.5rem'
  },
  '@md': {
    fontSize: '1.5rem'
  }
})

interface Props {
  onOpenInvitationClicked?: () => void;
  guestName?: string;
}

const Title = ({ onOpenInvitationClicked }: Props) => {
  const config = useContext(GuestContext);
  const { width, height } = useWindowSize();
  const { t } = useTranslation('greeting');

  const sectionRef = useRef<any>(null);
  const [isLocked, setIsLocked] = useState(true);
  const [isBgImageLoaded, setIsBgImageLoaded] = useState(false);
  useLockBodyScroll(isLocked);

  useEffect(() => {
    if (!config) {
      return
    }
    const img = new Image();
    img.src = config.titleImage.src;
    img.onload = () => {
      setIsBgImageLoaded(true);
    }
  }, [config]);
  const bgCss = useMemo(() => isBgImageLoaded ? {
    '--bg-img': `url(${config?.titleImage.src})`,
    '--blur-config': 'blur(2px) brightness(0.5)',
    '--bg-position': 'top'
  } : {
    '--bg-img': `url(${config?.titleImage.srcThumb})`,
    '--blur-config': 'blur(6px) brightness(0.5)',
    '--bg-position': 'top'
  }, [isBgImageLoaded, config?.titleImage])
  if (!config) {
    return <></>
  }

  return (
    <React.StrictMode>
      <Confetti
        width={width}
        height={height}
        numberOfPieces={300}
        gravity={0.2}
        colors={['#FFCDD2', '#F8BBD0', '#D1C4E9']}
        recycle={false}
        style={{ position: 'fixed' }}
      />
      <Section css={bgCss} ref={sectionRef}>
        <Layout>
          <Header>
            <CardHeader className="text-invert">
              {t('wedding')}
            </CardHeader>
            <BrideGroomShortName className="font-cursive text-invert">
              {config.groom.shortName} &amp; {config.bride.shortName}
            </BrideGroomShortName>
          </Header>
          <Footer>
            <GuestName
              className="text-invert"
              dangerouslySetInnerHTML={{ __html: t('salutation', { guest: config.guest.name }) }}
            />
              <Button size="large" type="default" icon={<MailOutlined />} onClick={() => {
                setIsLocked(false)
                onOpenInvitationClicked && onOpenInvitationClicked()
              }}>
                {t('openInvitation')}
              </Button>
          </Footer>
        </Layout>
      </Section>
    </React.StrictMode>
  );
}

export default Title;
