import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import en from './locales/en.json';
import id from './locales/id.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = { en, id };

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    lng: 'id',
    resources,
    detection: {
      lookupQuerystring: 'lang',
    },
    ns: ['common', 'greeting'],

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;